import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const VideoPlayIcon = () => {
  const [isMobile] = useIsMobile();
  return (
    <div className='player-button-overlay'>
      <Image
        alt='hafla play icon'
        className='ms-1 cursor-pointer rounded-full bg-transparent flex items-center justify-center z-10'
        height={isMobile ? 32 : 50}
        src={`${staticMediaStoreBaseURL}/event-glimpses/play-button-v1-icon.svg`}
        width={isMobile ? 32 : 50}
      />
    </div>
  );
};

export default VideoPlayIcon;
