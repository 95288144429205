const haflaServices = [
  {
    id: 1,
    image: '/why-choose-hafla-icons/price-guarantee-v1-icon.svg',
    name: 'Best Price Guarantee'
  },
  {
    id: 2,
    image: '/why-choose-hafla-icons/unlimited-supplies-v1-icon.svg',
    name: 'Unlimited Supplies'
  },
  {
    id: 3,
    image: '/why-choose-hafla-icons/trusted-quality-v1-icon.svg',
    name: 'Trusted Quality Partners'
  },
  {
    id: 4,
    image: '/why-choose-hafla-icons/customer-service-v1-icon.svg',
    name: 'Fast Customer Service'
  },
  {
    id: 5,
    image: '/why-choose-hafla-icons/quick-delivery-v1-icon.svg',
    name: 'Quick Delivery'
  }
];

export default haflaServices;
