import Image from 'next/image';

import { Text } from 'components/atomic/atoms';
import { planEventWithHebaURL, staticMediaStoreBaseURL } from 'config/common';

const AboutContent = () => (
  <div className='grid-container-about-section flex flex-row items-center w-full justify-around'>
    <Text
      className='about-bottom-text'
      content='Your Event is just a click away !'
    />
    <a
      href={planEventWithHebaURL}
      target='_blank'
      className='flex py-2 rounded-full bg-white w-48 text-black text-center uppercase cursor-pointer justify-center px-3'
      rel='noreferrer'
    >
      <div className='flex flex-row w-full justify-around'>
        <div className='uppercase'>Get Started</div>
        <Image
          className='relative'
          alt='arrow right icon'
          height={10}
          src={`${staticMediaStoreBaseURL}/icons/arrowright.svg`}
          width={14}
        />
      </div>
    </a>
  </div>
);

const AboutSection = () => (
  <div className='relative'>
    <Image
      width={1850}
      height={390}
      layout='responsive'
      alt='Mountains'
      src={`${staticMediaStoreBaseURL}/banners/your_event.jpg`}
      className='block object-cover'
    />
    <div className='absolute inset-0 bg-black bg-opacity-50'></div>
    <div className='absolute inset-0 flex items-center justify-center'>
      <AboutContent />
    </div>
  </div>
);

export default AboutSection;
