import dynamic from 'next/dynamic';
import { Fragment } from 'react';

import {
  BecomePartnerWeb,
  TopBrandsWeb,
  TrendingServices,
  TrustedPartners
} from 'components/atomic/molecules';
import Header from 'components/Common/header/Header';
import AboutSection from 'components/homePage/web/AboutSection';
import EventsGlimpses from 'components/homePage/web/EventsGlimpses';
import FeaturedCategories from 'components/homePage/web/FeaturedCategories';
import HaflaBlog from 'components/homePage/web/HaflaBlog';
import WhyChooseHafla from 'components/homePage/web/WhyChooseHafla';
import Layout from 'components/layout/DefaultLayout';
import haflaServices from 'data/why-choose-hafla';

const HowDoesItWork = dynamic(
  () => import('components/homePage/HowDoesItWork'),
  {
    ssr: false
  }
);

const isActive = false;

const WebHome = ({ data, bannerTranslation }) => (
  <Fragment>
    <Header
      homeScreen={true}
      categories={data.parentCategories}
    />
    <Layout
      paramData={{
        bannerTranslate: bannerTranslation,
        translate: bannerTranslation,
        eventCategory: 'home',
        homeScreen: true
      }}
    >
      <TopBrandsWeb />
      <TrendingServices />
      <FeaturedCategories />
      <TrustedPartners />
      <EventsGlimpses />
      <BecomePartnerWeb />
      <WhyChooseHafla {...{ haflaServices }} />
      {isActive && <HowDoesItWork />}
      <HaflaBlog posts={data?.blogPost} />
      <AboutSection />
    </Layout>
  </Fragment>
);

export default WebHome;
