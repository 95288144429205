import { Text, TopBrandsRow } from '@/components/atomic/atoms';

const topBrandLogosFirstRow = [
  {
    logoURL: 'Amazon.png',
    alt: 'Amazon'
  },
  {
    logoURL: 'BCG.png',
    alt: 'BCG'
  },
  {
    logoURL: 'Collines Aerospace.png',
    alt: 'Collines Aerospace'
  },
  {
    logoURL: 'DAMAC.png',
    alt: 'DAMAC'
  }
];

const topBrandLogosSecondRow = [
  {
    logoURL: 'DWTC.png',
    alt: 'DWTC'
  },
  {
    logoURL: 'Emaar.png',
    alt: 'Emaar'
  },
  {
    logoURL: 'EIF.png',
    alt: 'EIF'
  },
  {
    logoURL: 'Ford.png',
    alt: 'Ford'
  }
];

const topBrandLogosThirdRow = [
  {
    logoURL: 'Google.png',
    alt: 'Google'
  },
  {
    logoURL: 'Pirelli.png',
    alt: 'Pirelli'
  },
  {
    logoURL: 'Samsung.png',
    alt: 'Samsung'
  },
  {
    logoURL: 'Shein.png',
    alt: 'Shein'
  }
];

const topBrandLogosForthRow = [
  {
    logoURL: 'Shell.png',
    alt: 'Shell'
  },
  {
    logoURL: 'Solana.png',
    alt: 'Solana'
  },
  {
    logoURL: 'Talabat.png',
    alt: 'Talabat'
  },
  {
    logoURL: 'Total Energies.png',
    alt: 'Total Energies'
  }
];

const BlankRowAndCells = () => (
  <tr>
    {topBrandLogosFirstRow.map(({ logoURL }, index) => (
      <td key={`${logoURL}-${index}`}></td>
    ))}
  </tr>
);

const TopBrandsMobile = () => (
  <div className='top-brands-header-mobile bg-white'>
    <div className='block'>
      <Text
        {...{
          className:
            'text-light-silver text-xs md:text-xl font-medium uppercase text-center top-brands-header-mobile md:top-brands-header-web',
          content: 'Trusted by top brands'
        }}
      />
      <div className='flex items-center justify-center justify-self-center justify-items-center mt-5 md:mt-0 pb-7'>
        <table className='border-collapse bg-white table-container'>
          <tbody>
            <BlankRowAndCells />
            <TopBrandsRow {...{ renderRow: topBrandLogosFirstRow }} />
            <TopBrandsRow {...{ renderRow: topBrandLogosSecondRow }} />
            <TopBrandsRow {...{ renderRow: topBrandLogosThirdRow }} />
            <TopBrandsRow {...{ renderRow: topBrandLogosForthRow }} />
            <BlankRowAndCells />
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default TopBrandsMobile;
