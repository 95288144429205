const eventGlimpsesList = [
  {
    id: 1,
    url: 'https://youtu.be/OkzXSfwu6nU',
    thumbnailUrl: '/event-glimpses/baby-showers-thumb.jpg',
    title: 'A Grand Gala Dinner'
  },
  {
    id: 2,
    url: 'https://youtu.be/EQmVuPhn6OE',
    thumbnailUrl: '/event-glimpses/kids-birthdays-thumb.jpg',
    title: 'A Luxury Yacht Family Day'
  },
  {
    id: 3,
    url: 'https://youtu.be/9pKV1mIkADc',
    thumbnailUrl: '/event-glimpses/casual-get-together-thumb.jpg',
    title: 'Carnival Lunch Celebration'
  },
  {
    id: 4,
    url: 'https://youtu.be/gFm-Gh7wdo0',
    thumbnailUrl: '/event-glimpses/corporate-events-thumb.jpg',
    title: 'Dazzling LED Sangeet Night'
  },
  {
    id: 5,
    url: 'https://youtu.be/Y5pCGZHLKtg',
    thumbnailUrl: '/event-glimpses/corporate-events-thumb.jpg',
    title: 'Dazzling LED Sangeet Night'
  },
  {
    id: 6,
    url: 'https://youtu.be/UwNDXY18B6Q',
    thumbnailUrl: '/event-glimpses/corporate-events-thumb.jpg',
    title: 'Dazzling LED Sangeet Night'
  }
];

export default eventGlimpsesList;
