import PropTypes from 'prop-types';

const titleClassName =
  'font-google-basic text-2xl lg:text-45px text-nero uppercase leading-8 lg:leading-16 text-center font-medium tracking-widest';
const SectionHeading = ({ letterSpacing = 3, title, subTitle, underline }) => (
  <div>
    <div
      style={{ letterSpacing }}
      className={titleClassName}
    >
      {title}
    </div>
    {subTitle && (
      <div
        style={{ letterSpacing: 2 }}
        className='text-nero text-xs md:text-xl leading-4 font-bold mt-1 text-center Montserrat font-normal'
      >
        {subTitle}
      </div>
    )}
    {underline && (
      <hr
        style={{
          height: 5,
          width: 80,
          borderRadius: 20,
          background:
            'linear-gradient(90deg, #F38023 -90.01%, #DD579D 205.57%, #EE3D24 467.37%)',
          margin: 'auto',
          marginTop: 10
        }}
      />
    )}
  </div>
);
SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  underline: PropTypes.bool
};

export default SectionHeading;
