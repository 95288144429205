import Image from 'next/image';

import {
  IconMobile,
  RibbonButton,
  SectionHeading
} from '@/components/atomic/atoms';
import { planEventWithHebaURL, staticMediaStoreBaseURL } from 'config/common';

const BottomHeader = () => (
  <div className='flex flex-row mt-4 Montserrat px-5'>
    <div
      className='self-center leading-5 text-lg'
      style={{ lineHeight: '23px' }}
    >
      <div className='font-extrabold'>
        {`UAE's largest event services team with 17,000+ events enabled`}
      </div>
      <div className='pt-1'>
        {`Whether it's a gala dinner or a wedding ceremony, you need a venue or an
        artist, Hafla will help you get it with no stress..`}
      </div>
    </div>
  </div>
);

const WhyChooseHaflaMobile = ({ haflaServices = [] }) => (
  <div className='mt-5 text-center'>
    <SectionHeading
      title='WHY CHOOSE HAFLA'
      underline
    />
    <BottomHeader />
    <div className='flex flex-row flex-wrap items-start w-full mt-5 mb-5 justify-evenly'>
      {haflaServices.map(({ id, name, image }) => (
        <IconMobile
          key={id}
          {...{
            title: name,
            className: 'img-center h-20 w-20',
            alt: name,
            url: `${staticMediaStoreBaseURL}${image}`
          }}
        />
      ))}
    </div>
    <div className='flex flex-row items-center justify-center mb-10'>
      <div className='flex flex-row w-2/3 justify-content-center place-content-center'>
        <RibbonButton
          className={`flex flex-nowrap ribbon-button-styles rounded-full text-10px uppercase tracking-wider ml-0 pl-2 font-google-basic font-normal h-11 w-full`}
          onClick={() => window.open(planEventWithHebaURL, '_blank')}
        >
          PLAN YOUR EVENT
          <Image
            alt='arrow icon'
            className='self-center place-self-center transform rotate-180'
            height={4}
            layout='fixed'
            src={`${staticMediaStoreBaseURL}/icons/back.svg`}
            width={6}
          />
        </RibbonButton>
      </div>
    </div>
  </div>
);

export default WhyChooseHaflaMobile;
