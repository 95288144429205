import dynamic from 'next/dynamic';

const VideoCard = dynamic(
  () => import('components/atomic/molecules/homePage/web/VideoCard'),
  {
    ssr: false
  }
);

const VideoCardListMobile = ({ cardList = [], scrollRef, handleScroll }) => (
  <div className='flex ps-2.5 xxxs:ps-5 event-glimpses-column-container'>
    <div
      className='event-glimpses-grid-container flex-nowrap no-scrollbar items-center card-scroll'
      ref={scrollRef}
      onScroll={handleScroll}
    >
      {cardList.map(({ id, title, url, thumbnailUrl }) => (
        <VideoCard
          key={id}
          {...{ title, url, thumbnailUrl }}
        />
      ))}
    </div>
  </div>
);

export default VideoCardListMobile;
