export const pdfConstants = {
  TRN: '100475504500003',
  companyName: 'Evinops Limited (Hafla)',
  blackColor: [0, 0, 0],
  greyColor: [240, 240, 240],
  tableHeaderBackgroundColor: [224, 224, 224],
  greenColor: [25, 102, 25],
  address: [
    [
      'DD-14-124-053, 14 Floor, WeWork Hub71,\nAl Khatem Tower, ADGM Square, Al Maryah Island,\nAbu Dhabi, UAE PO Box: 46617'
    ]
  ],
  haflaEmail: 'contactus@hafla.com',
  haflaLandlineMaqsam: '+971 4561 6897',
  haflaWebsiteText: 'www.hafla.com',
  haflaWebsiteUrl: 'https://www.hafla.com'
};

export const paymentDetailBody = [
  ['Business Name - Evinops Limited'],
  ['License # - 000005703'],
  ['Payment Mode - Cash / Bank Transfer / Online Credit Card Payment'],
  [''],
  ['Bank Details'],
  ['Name: Evinops Limited'],
  ['Bank: Mashreq Bank'],
  ['Account No: 019100732211'],
  ['IBAN: AE460330000019100732211'],
  ['Swift Code: BOMLAEAD']
];

export const jsPDFOptions = {
  startX: 16,
  PDF_ORIENTATION: 'portrait',
  PAPER_SIZE: 'a4'
};
