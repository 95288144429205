module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en'],
    localeDetection: false
  },
  trailingSlash: false,
  react: {
    useSuspense: false,
    wait: true
  }
};
