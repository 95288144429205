import Image from 'next/image';

import { planEventWithHebaURL, staticMediaStoreBaseURL } from 'config/common';

const AboutHaflaFooterContent = () => (
  <div
    className='rounded-full text-center text-black
flex bg-white font-Montserrat font-black w-40 p-2 justify-center m-auto'
  >
    <a
      onClick={() => window.open(planEventWithHebaURL, '_blank')}
      className='flex text-xs font-normal font-medium w-28'
    >
      <div className='flex flex-row w-full justify-evenly'>
        <div className='uppercase'>Get Started</div>
        <Image
          className='relative'
          alt='arrow right icon'
          height={8}
          src={`${staticMediaStoreBaseURL}/icons/arrowright.svg`}
          width={8}
        />
      </div>
    </a>
  </div>
);

const AboutSectionMobile = () => (
  <div className='bg-white'>
    <div className='about-hafla-footer bg-no-repeat bg-cover'>
      <div
        className='opacity-100 h-full w-full
margin-auto text-center py-5'
      >
        <div
          className='margin-auto text-white font-Montserrat text-xl
font-black mb-2'
        >
          Your event is just a click away!
        </div>
        <AboutHaflaFooterContent />
      </div>
    </div>
  </div>
);

export default AboutSectionMobile;
