/*
  #TODO: Use uniform property names to avoid property picking.
    IE:  posts={data.blogPost} should have been posts={data.posts}
  #TODO: Remove unused external calls to improve performance
    Ex: getAllChildCategories.
  #TODO: Export relevant local components as Atoms / Molecules / Organisms.
*/

import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect, useState } from 'react';

import MobileHome from 'components/homePage/Mobile';
import WebHome from 'components/homePage/Web';
import { useIsMobile } from 'helpers/screenResolutionHelper';
import { getBlogsPosts } from 'services/blog.service';
import { getCategories } from 'services/categories.service';
import { getPublishedCollections } from 'services/collection.service';
import { getEventGallery } from 'services/eventGallery.service';

// TODO: Faizan data is vague, explicitly specify the keys ex: {parentCategories, eventGallery, etc}
export default function Home(data) {
  const [isMobileView] = useIsMobile();
  const [parentCategoriesData, setParentCategoriesData] = useState([]);
  const bannerTranslation = useTranslation('homeBanner');

  useEffect(() => {
    if (data.parentCategories && data.parentCategories.length) {
      setParentCategoriesData(data.parentCategories);
    }
  }, [data.parentCategories]);

  const Component = isMobileView ? MobileHome : WebHome;

  return (
    <Component
      {...{
        data,
        bannerTranslation,
        parentCategoriesData
      }}
    />
  );
}

export async function getStaticProps({ locale = 'en' }) {
  // TODO: Streamline the signatures of the functions to cut-down on code volume.
  const values = await Promise.all([
    getEventGallery('home'), // TODO: Faizan since we are not invoking any api to get the home page event gallery; can you use JSON directly where its needed ?   -- if this can be used directly update all other places too
    getCategories(),
    getPublishedCollections(0, 3),
    getBlogsPosts(),
    serverSideTranslations(locale, [
      'homeBanner',
      'event-categories',
      'customerReviewCard',
      'googleReviewCard',
      'industryPartners',
      'haflaWorks',
      'eventGallery',
      'footer'
    ])
  ]);

  return {
    props: {
      eventGallery: values[0].entity.data,
      parentCategories: values[1],
      collections: values[2].entity,
      blogPost: values[3].entity,
      ...values[4]
    },
    revalidate: 900
  };
}
