import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from '@/config/common';

const ReadMeArticle = ({ linkUrl }) => (
  <div className='pb-1 w-full pt-5'>
    <Link href={linkUrl}>
      <div className='flex items-center'>
        <span className='text-base font-semibold text-left blog-font-style'>
          Read Full Article
        </span>
        <div className='relative ms-1.5 h-2.6 flex pl-1'>
          <Image
            alt='right-angle'
            src={`${staticMediaStoreBaseURL}/icons/arrowright.svg`}
            height={10}
            width={14}
            layout='fixed'
          />
        </div>
      </div>
    </Link>
  </div>
);

export default ReadMeArticle;
