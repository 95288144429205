import { Icon } from 'components/atomic/atoms';
import { WhyChooseHeader } from 'components/atomic/molecules';
import { planEventWithHebaURL, staticMediaStoreBaseURL } from 'config/common';

const WhyChooseHafla = ({ haflaServices }) => (
  <div className='pt-10'>
    <WhyChooseHeader
      {...{
        title: 'WHY CHOOSE HAFLA',
        buttonText: 'Plan your event',
        buttonUrl: planEventWithHebaURL
      }}
    />
    <div className='flex flex-nowrap items-start w-11/12 mx-auto justify-evenly mt-10 mb-20'>
      {haflaServices.map(({ id, name, image }) => (
        <Icon
          key={id}
          {...{
            alt: name,
            className: 'img-center h-30 w-30',
            containerClass: 'shadow-none',
            title: name,
            url: `${staticMediaStoreBaseURL}${image}`
          }}
        />
      ))}
    </div>
  </div>
);

export default WhyChooseHafla;
