import Image from 'next/image';
import { useEffect } from 'react';

import { Button, Text } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import phoneConfig from '@/config/phone';
import { captureGTMEventPurchaseCompletion } from '@/lib/gtm';
import { COOKIE_MAP, removeCookie } from '@/services/cookie.service';
import { getAuth } from '@/services/identity.service';
import {
  PAYMENT_RECEIPT_STATUS,
  PAYMENT_SOURCE_TYPE,
  getPaymentResponseRedirectURLs
} from '@/services/payment.service';
import { getPurchaseEventInfoForGA } from '@/services/user.service';

const RedirectToHomeCTA = ({ onClick }) => (
  <Button
    {...{
      children: 'Close',
      className:
        'border border-pumpkins text-brand h-11 px-8 md:py-4 text-sm font-medium w-full rounded',
      onClick
    }}
  />
);

const PaymentResponseCTA = ({ label, onClick, disabled = false }) => (
  <Button
    {...{
      children: label,
      className:
        'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white h-11 px-8 md:py-4 text-sm font-medium w-full rounded',
      onClick,
      disabled
    }}
  />
);

const PaymentSuccessCTA = ({
  cancelURL,
  forwardURL,
  label,
  routeToPath,
  userId
}) => {
  useEffect(() => removeCookie({ name: COOKIE_MAP.SELECTED_CART }), []);

  const auth = getAuth();
  const loggedInUserPayingForSelf = auth?.id === userId;
  const guestUser = auth?.isGuest; // TODO: Sidd - we should use some common identity function
  return (
    <div className='flex flex-col gap-4'>
      {(loggedInUserPayingForSelf || guestUser) && ( // TODO: we are disturbing the refactored code by adding conditions here in case you want to hide a JSX check if forwardURL length is 0 or forwardURL is null, and setting forwardURL to 0 length or null should be done in getPaymentResponseRedirectURLs - QA nai ek bug bataya, usko Root cause sai fix karo code patch kyu karo -- PaymentSuccessCTA should not have logic related to loggedInUserPayingForSelf or guestUser -- SIDD will fix this, faizan to remind on 16th Jan 2024
        <PaymentResponseCTA
          {...{ label, onClick: () => routeToPath(forwardURL) }}
        />
      )}
      <RedirectToHomeCTA {...{ onClick: () => routeToPath(cancelURL) }} />
    </div>
  );
};

const PaymentFailedCTA = ({ cancelURL, forwardURL, label, routeToPath }) => (
  <div className='flex flex-col gap-4'>
    <PaymentResponseCTA
      {...{ label, onClick: () => routeToPath(forwardURL) }}
    />
    <RedirectToHomeCTA {...{ onClick: () => routeToPath(cancelURL) }} />
  </div>
);

const getDetailsSpecificToPaymentResponseStatus = ({
  isUserLoggedIn,
  paymentReceiptStatus,
  userId
}) => {
  let details;
  switch (paymentReceiptStatus) {
    case PAYMENT_RECEIPT_STATUS.FAIL:
      details = {
        CTA: PaymentFailedCTA,
        label: 'Try Again',
        responseTitle: 'Payment Failed!',
        statusIcon: 'payment-failed.svg',
        statusMessage: 'Oh no! Something went wrong.'
      };
      break;
    case PAYMENT_RECEIPT_STATUS.PASS:
    default:
      details = {
        CTA: PaymentSuccessCTA,
        userId,
        label: `${!isUserLoggedIn ? 'Login to ' : ''}view order details`,
        responseTitle: 'Payment Successful!',
        statusIcon: 'payment-success.svg',
        statusMessage: 'Your payment is successful.'
      };
      break;
  }
  return details;
};

const sendDataToGAForPurchaseEvent = async ({
  isUserLoggedIn,
  orderId,
  paymentReceiptStatus,
  paymentSourceType,
  paymentFlowSource,
  transactionAmount,
  userId
}) => {
  const shouldSendDataToGAForPurchaseEvent =
    paymentSourceType === PAYMENT_SOURCE_TYPE.CART &&
    paymentReceiptStatus === PAYMENT_RECEIPT_STATUS.PASS &&
    orderId &&
    transactionAmount;
  if (shouldSendDataToGAForPurchaseEvent) {
    let purchaseEventInfoForGA;
    const auth = getAuth();
    const loggedInUserPayingForSelf = auth?.id === userId;
    if (isUserLoggedIn && loggedInUserPayingForSelf) {
      purchaseEventInfoForGA = await getPurchaseEventInfoForGA({
        orderId,
        paymentFlowSource,
        userId: auth.id
      });
    }
    if (!purchaseEventInfoForGA) {
      purchaseEventInfoForGA = {
        orderId,
        orderTotal: transactionAmount,
        paymentFlowSource
      };
    }
    captureGTMEventPurchaseCompletion(purchaseEventInfoForGA);
  }
};

const PaymentResponse = ({
  asPath,
  isUserLoggedIn,
  orderId,
  paymentFlowSource,
  paymentId,
  paymentReceiptStatus,
  paymentSourceType,
  referenceId,
  routeToPath,
  transactionAmount,
  userId
}) => {
  useEffect(() => {
    sendDataToGAForPurchaseEvent({
      isUserLoggedIn,
      orderId,
      paymentFlowSource,
      paymentReceiptStatus,
      paymentSourceType,
      transactionAmount,
      userId
    });
  }, []);
  const { forwardURL, cancelURL } = getPaymentResponseRedirectURLs({
    asPath,
    isUserLoggedIn,
    orderId,
    paymentFlowSource,
    paymentReceiptStatus,
    paymentSourceType,
    referenceId,
    userId
  });

  const { CTA, responseTitle, statusIcon, statusMessage, label } =
    getDetailsSpecificToPaymentResponseStatus({
      isUserLoggedIn,
      paymentReceiptStatus,
      userId
    });

  return (
    <div className='flex justify-center flex-col gap-4 text-center w-100 md:w-112 mx-auto py-10 px-4'>
      <Text
        {...{
          content: responseTitle,
          className: 'text-xl md:text-2xl font-medium text-nero'
        }}
      />
      <div className='py-5'>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/${statusIcon}`}
          width={112}
          height={112}
          alt='Payment status'
        />
      </div>
      <div className='text-sm md:text-base font-light mb-0'>
        <Text
          {...{
            content: statusMessage,
            HtmlTag: 'span'
          }}
        />
      </div>
      <div className='text-sm md:text-base flex flex-col font-light mb-4'>
        <Text
          {...{
            content: `Your payment tracking id is`,
            HtmlTag: 'span'
          }}
        />
        <Text
          {...{ content: paymentId, className: 'text-blue text-primary-600' }}
        />
      </div>
      <CTA
        {...{
          cancelURL,
          forwardURL,
          label,
          routeToPath,
          userId
        }}
      />
      <div className='text-sm md:text-base font-light mb-4'>
        <Text
          {...{
            content: 'For any assistance, please call us at ',
            HtmlTag: 'span'
          }}
        />
        <Text
          {...{
            content: ` ${phoneConfig.phoneNumbers.zendeskWhatsappMaqsam}`,
            HtmlTag: 'span',
            className: 'text-brand'
          }}
        />
      </div>
    </div>
  );
};

export default PaymentResponse;
