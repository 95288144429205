import Image from 'next/image';
import { useRouter } from 'next/router';

import { useUIContext } from 'components/context/uiContext/ManagedUiContext';
import { staticMediaStoreBaseURL } from 'config/common';
import phoneConfig from 'config/phone';
import { getFormattedNumber, PNF } from 'helpers/formatPhoneNumber';
import { captureGTMEventCTAClick, GTM_EVENTS_CTA_NAME } from 'lib/gtm';

const { phoneNumbers } = phoneConfig;

const uaeLandlineNumber = getFormattedNumber(
  phoneNumbers.haflaLandlineMaqsam,
  'UAE',
  PNF.E164
);

const CallIconWeb = ({ onScrollNotInHomeScreen }) => (
  <Image
    className='cursor-pointer'
    width={33}
    height={33}
    src={`${staticMediaStoreBaseURL}${
      onScrollNotInHomeScreen
        ? '/icons/call-round-mobile.svg'
        : '/icons/call-round-white.svg'
    }`}
    layout='fixed'
    alt='Call'
  />
);

const CallIconMobile = () => (
  <Image
    className='cursor-pointer'
    width={24}
    height={24}
    src={`${staticMediaStoreBaseURL}/icons/call-round-mobile.svg`}
    layout='fixed'
    alt='Call'
  />
);

const CallIconView = {
  web: CallIconWeb,
  mobile: CallIconMobile
};

export default function CallIcon({ onScrollNotInHomeScreen }) {
  const uiContext = useUIContext();
  const { surface } = uiContext;
  const CallIconForDevice = CallIconView[surface];
  const router = useRouter();

  return (
    <div className='flex'>
      <a
        id='call-icon'
        href={`tel:${uaeLandlineNumber}`}
        className='flex'
        onClick={() =>
          captureGTMEventCTAClick({
            ctaName: GTM_EVENTS_CTA_NAME.PHONE_CALL,
            path: router.pathname
          })
        }
      >
        <CallIconForDevice {...{ onScrollNotInHomeScreen }} />
      </a>
    </div>
  );
}
