import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from 'config/common';
import featuredCategories from 'data/featured-categories';

const FeaturedCategories = () => (
  <div className='py-10 px-4'>
    <div className='font-google-basic text-2xl lg:text-45px text-nero uppercase leading-8 lg:leading-16 text-center font-medium tracking-widest'>
      Explore Services
    </div>
    <div className='flex justify-center mb-6'>
      <div className='h-1 w-24 bg-gradient-to-r from-pink-500 to-yellow-500 rounded mt-4'></div>
    </div>
    <div className='grid grid-cols-2 gap-4'>
      {featuredCategories.map(({ href, logoUrl, name }, id) => (
        <Link
          key={id}
          href={href}
        >
          <div className='flex flex-col items-center overflow-hidden gap-2'>
            <div className='w-full h-full relative shadow'>
              <Image
                alt={name}
                className='rounded-lg'
                height={110}
                width={100}
                layout='responsive'
                objectFit='cover'
                src={`${staticMediaStoreBaseURL}${logoUrl}`}
              />
            </div>
            <div className='w-full'>
              <div className='font-Montserrat font-semibold'>{name}</div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  </div>
);

export default FeaturedCategories;
