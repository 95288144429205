import Image from 'next/image';
import { useRouter } from 'next/router';

import { CollectionSubHeader, RibbonButton } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const BottomHeader = () => (
  <div className='flex flex-row mt-4 Montserrat'>
    <div className='self-center leading-5 text-xl'>
      <div className='font-extrabold'>
        {`UAE's largest event services team with 17,000+ events enabled`}
      </div>
      <div>{`Whether it's a gala dinner or a wedding ceremony, and whether`}</div>
      <div>
        you need a venue or an artist, Hafla will help you get it with no
        stress.
      </div>
    </div>
  </div>
);

const WhyChooseHeader = ({
  title,
  subTitle,
  buttonText,
  buttonUrl,
  isOpenInSamePage
}) => {
  const Router = useRouter();
  return (
    <div className='flex flex-row justify-around'>
      <div>
        <CollectionSubHeader
          {...{
            title,
            subTitle,
            underline: true,
            titleClass:
              'font-google-basic flex flex-row uppercase tracking-widest font-medium'
          }}
        />
        <BottomHeader />
      </div>
      <div className='flex flex-row self-start mt-3'>
        <RibbonButton
          className='flex flex-nowrap font-google-basic font-normal rounded-full uppercase ribbon-button-styles tracking-wider'
          onClick={() =>
            isOpenInSamePage
              ? Router.push(buttonUrl)
              : window.open(buttonUrl, '_blank')
          }
        >
          {buttonText}
          <Image
            alt='arrow icon'
            className='self-center place-self-center transform rotate-180'
            height={10}
            layout='fixed'
            src={`${staticMediaStoreBaseURL}/icons/back.svg`}
            width={14}
          />
        </RibbonButton>
      </div>
    </div>
  );
};

export default WhyChooseHeader;
