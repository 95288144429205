import _ from 'lodash';

const featuredCategories = [
  {
    href: '/categories/event-equipment/exhibition-furniture',
    logoUrl: '/featured-categories/production.jpg',
    name: 'Production',
    sortOrder: 2
  },
  {
    href: 'https://book.hafla.com/pages/event-venues',
    logoUrl: '/featured-categories/venues.jpg',
    name: 'Venue',
    sortOrder: 1
  },
  {
    href: '/categories/event-equipment/event-furniture',
    logoUrl: '/featured-categories/rentals.jpg',
    name: 'Rentals',
    sortOrder: 3
  },
  {
    href: 'https://book.hafla.com/pages/decor-vertical?sort_by=best-selling&page=3&filter.p.m.custom.brand_name=Hafla&filter.v.option.delivery+location=Abu+Dhabi&utm_source=mk_test&utm_medium=mk_test&utm_content=mk_test',
    logoUrl: '/featured-categories/decor.jpg',
    name: 'Decor',
    sortOrder: 4
  },
  {
    href: 'https://book.hafla.com/pages/food-and-beverage',
    logoUrl: '/featured-categories/catering.jpg',
    name: 'Catering',
    sortOrder: 5
  },
  {
    href: '/categories/people-and-services',
    logoUrl: '/featured-categories/artists.jpg',
    name: 'Artists',
    sortOrder: 6
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

export default featuredCategories;
