import PropTypes from 'prop-types';

const defaultClassName = `md:h-auto bg-gradient-to-r from-light-orange
  via-dark-orange to-rose md:w-auto text-white
  text-sm focus:outline-none rounded-lg ml-5 font-bold`;

const RibbonButton = ({
  isEventPlan,
  children = [],
  className = '',
  ...props
}) => (
  <button
    {...{
      style: {
        letterSpacing: `${isEventPlan ? 0 : '1.7px'}`,
        background:
          'linear-gradient(50deg, rgb(220, 124, 10) 7.01%, rgb(202, 87, 121) 56.57%, rgb(205, 76, 91) 100.37%)'
      },
      className: `${defaultClassName} ${className}`,
      ...props
    }}
  >
    {children.map((child) => child)}
  </button>
);

export default RibbonButton;
RibbonButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string
};
