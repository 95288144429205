const phoneConfig = {
  phoneNumbers: {
    haflaLandlineMaqsam: '+97145616897',
    hebaWhatsapp: '+971501164825',
    zendeskWhatsappMaqsam: '+971501169428'
  },
  mobileInputError: 'Invalid mobile number',
  firstCountryToShow: ['AE', 'SA', 'IN'],
  mobileNotStartZeroRegex: /^[1-9][0-9]{3,13}$/,
  mobileBasicValidationFailureError:
    'Only 9 or 10 digits are allowed, please exclude country code or 0'
};

export default phoneConfig;
