import Image from 'next/image';
import PropTypes from 'prop-types';

const Icon = ({
  anchorTagClass,
  alt = '',
  containerClass = '',
  imageHeight = 120,
  imageWidth = 120,
  onClick = () => {},
  title,
  titleClassName = 'text-center font-family:Brandon Text items-center w-40 font-semibold mt-5 mb-5 uppercase',
  url,
  ...props
}) => (
  <div>
    <div
      className={`rounded-full w-15 xxxs:w-full mx-auto text-center shadow-2xl ${containerClass}`}
    >
      {props.href ? (
        <a
          className={anchorTagClass}
          onClick={onClick}
          href={props.href}
        >
          <Image
            alt={alt}
            width={imageWidth}
            height={imageHeight}
            layout='fixed'
            style={{ zIndex: '-1' }}
            {...{ ...props, className: props.className, src: url }}
          />
        </a>
      ) : (
        <Image
          alt={alt}
          width={188}
          height={172}
          layout='fixed'
          {...{ ...props, src: url }}
        />
      )}
    </div>
    {props.href ? (
      <a
        onClick={onClick}
        href={props.href}
      >
        <div className={titleClassName}>{title}</div>
      </a>
    ) : (
      <div className={titleClassName}>{title}</div>
    )}
  </div>
);

export default Icon;

Icon.propTypes = {
  alignment: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  imageHeight: PropTypes.number,
  imageWight: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string
};
