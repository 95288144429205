import _ from 'lodash';

const trustedPartners = [
  {
    alt: 'atlantis',
    url: '/trusted-partners/atlantis.svg',
    sortOrder: 1
  },
  {
    alt: 'marriott',
    url: '/trusted-partners/marriott.svg',
    sortOrder: 2
  },
  {
    alt: 'jumeirah',
    url: '/trusted-partners/jumeirah.svg',
    sortOrder: 3
  },
  { alt: 'fairmont', url: '/trusted-partners/fairmont.svg', sortOrder: 4 },
  { alt: 'hilton', url: '/trusted-partners/hilton.svg', sortOrder: 5 },
  { alt: 'raffles', url: '/trusted-partners/raffles.svg', sortOrder: 6 },
  { alt: 'rixos', url: '/trusted-partners/rixos.svg', sortOrder: 7 },
  { alt: 'radisson', url: '/trusted-partners/radisson.svg', sortOrder: 8 },
  { alt: 'habtoor', url: '/trusted-partners/habtoor.svg', sortOrder: 9 }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

export default trustedPartners;
