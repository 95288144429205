import Image from 'next/image';

import { Text, TextKeyValuePair } from '@/components/atomic/atoms';
import {
  homePageBannerDescriptionIconValuePair,
  planEventWithHebaURL,
  staticMediaStoreBaseURL
} from '@/config/common';

const PlanWithHaflaCTA = () => (
  <div
    className={`mt-8 flex flex-row cursor-pointer z-10 rounded-full h-14 w-80 bg-white items-center justify-between plan-with-ai-style hover:shadow-2xl`}
    onClick={() => {
      window.open(planEventWithHebaURL, '_blank');
    }}
  >
    <Text
      {...{
        className:
          'uppercase font-normal text-xl leading-6 plan-with-ai-text pl-7 tracking-widest',
        content: 'plan with hafla ai'
      }}
    />
    <div className='plan-with-ai-arrow-style rounded-full w-10 h-10 text-center place-content-center mr-2'>
      <Image
        className='self-center transform rotate-180'
        alt='plan with hafla ai icon'
        height={11}
        layout='fixed'
        src={`${staticMediaStoreBaseURL}/icons/back.svg`}
        width={16}
      />
    </div>
  </div>
);

const DesktopHomeBanner = () => (
  <div className='flex flex-row justify-between relative hero-background-web'>
    <div className='flex flex-col gap-3 mt-32 ml-10 justify-between'>
      <div>
        <TextKeyValuePair
          {...{
            label: 'Experience AI Powered Event Planning',
            labelClass: 'text-56 text-white font-normal w-10 hero-heading z-20',
            spaceTop: '',
            value: 'By Expert Planners, with Professional Partners,',
            valueClassName: 'text-white text-base font-medium w-2/4 z-20'
          }}
        />
        <Text
          {...{
            className: 'text-white text-base font-medium z-20 w-2/4',
            content: 'for Everything for Entire Event'
          }}
        />
        <PlanWithHaflaCTA />
      </div>
      <div className='bottom-center-align pb-5'>
        <div className='grid grid-cols-3'>
          {homePageBannerDescriptionIconValuePair.map(
            ({ icon, title, subTitle }, index) => (
              <div
                key={title}
                className={`flex gap-2 z-20 ${
                  index === homePageBannerDescriptionIconValuePair.length - 1
                    ? '-mx-3'
                    : 'mx-5'
                }`}
              >
                <Image
                  alt='ideas icon'
                  height={40}
                  layout='fixed'
                  src={`${staticMediaStoreBaseURL}/icons/${icon}`}
                  width={40}
                />
                <TextKeyValuePair
                  {...{
                    className: 'flex flex-col gap-half',
                    label: title,
                    labelClass: 'text-white text-base font-semibold',
                    spaceTop: '',
                    value: subTitle,
                    valueClassName: 'text-white text-sm font-medium'
                  }}
                />
              </div>
            )
          )}
        </div>
      </div>
      <div className='bottom-overlay-banner-description'></div>
    </div>
    <div className='banner-image-web'>
      <Image
        alt='Hafla desktop banner'
        height={558}
        objectFit='cover'
        src={`${staticMediaStoreBaseURL}/home-page-banner/home-page-banner.png`}
        width={936}
      />
    </div>
  </div>
);

export default DesktopHomeBanner;
