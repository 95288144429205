import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';
import phoneConfig from '@/config/phone';

const { phoneNumbers } = phoneConfig;

const ProductQuantityRangeAlertError = ({
  className,
  minBookable,
  maxBookable,
  showErrorRef
}) => (
  <div className={`relative mt-4 cursor-pointer ${className}`}>
    <div
      className='bg-error-base-bg p-3 flex gap-2 rounded-lg my-3'
      ref={showErrorRef}
    >
      <div className='w-10 h-10 self-center flex'>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/failed.svg`}
          width={16}
          height={16}
          alt='caution'
        />
      </div>
      <span className='text-sm font-light text-error-base'>
        Please add quantity between {minBookable} - {maxBookable} for this
        product. If you need more than {maxBookable} {''}
        quantities, kindly reach us @
        <a href={`tel:${phoneNumbers.haflaLandlineMaqsam}`}>
          {phoneNumbers.haflaLandlineMaqsam}
        </a>
        .
      </span>
    </div>
  </div>
);

export default ProductQuantityRangeAlertError;
