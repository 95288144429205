import Image from 'next/image';
import { useRouter } from 'next/router';

import { CollectionSubHeader, RibbonButton } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  GTM_EVENTS_CTA_NAME,
  PAGE_NAME,
  captureGTMEventCTAClick
} from '@/lib/gtm';

const CollectionHeader = ({
  title,
  subTitle,
  buttonText,
  buttonUrl,
  isOpenInSamePage
}) => {
  const Router = useRouter();
  return (
    <div className='flex flex-row justify-between'>
      <CollectionSubHeader
        {...{
          title,
          subTitle,
          underline: true,
          subPosition: 'top',
          titleClass: 'flex flex-row uppercase tracking-widest'
        }}
      />
      <div className='flex flex-row self-end'>
        {buttonText && (
          <RibbonButton
            className={`flex flex-nowrap font-google-basic text-sm rounded-full uppercase px-8 py-2 tracking-wider blog-ribbon-button-style`}
            onClick={() => {
              captureGTMEventCTAClick({
                ctaName: GTM_EVENTS_CTA_NAME.GALLERY_VIEW_ALL,
                pageName: PAGE_NAME.HOME_PAGE.label
              });
              return isOpenInSamePage
                ? Router.push(buttonUrl)
                : window.open(buttonUrl, '_blank');
            }}
          >
            {buttonText}
            <Image
              alt='arrow icon'
              className='self-center place-self-center transform rotate-180'
              height={10}
              layout='fixed'
              src={`${staticMediaStoreBaseURL}/icons/back.svg`}
              width={14}
            />
          </RibbonButton>
        )}
      </div>
    </div>
  );
};

export default CollectionHeader;
