import {
  CART_ITEM_STATUS,
  CART_ORDER_SOURCE,
  PRODUCT_STATUS,
  PRODUCT_TYPE
} from '@/config/common';
import { itemMediaListMapper } from '@/helpers/carousel';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import {
  convertToDateTimeString,
  convertToShortMonthDateFormat,
  isValidDate
} from '@/lib/time';
import { getCartDetails } from '@/services/cart.service';
import * as HttpService from '@/services/http.service';
import { getAuth } from '@/services/identity.service';
import {
  CART_ITEM_OPERATION_URL,
  DEFAULT_CART_CHECKOUT,
  EVENT_CART_CHECKOUT,
  GET_CHECKOUT_EVENTS_API_URL,
  GET_READ_ONLY_CART_FOR_PUBLIC
} from '@/services/url.service';

export const eventCartCheckout = ({ userCartId, cartId }) =>
  HttpService.postWithAuth(EVENT_CART_CHECKOUT({ userCartId, cartId }));

export const getReadOnlyCartForPublic = ({ cartId }) =>
  HttpService.getWithoutAuth(GET_READ_ONLY_CART_FOR_PUBLIC({ cartId }));

export const getCheckoutEventsAPI = () =>
  HttpService.getWithoutAuth(GET_CHECKOUT_EVENTS_API_URL);

export const defaultCartCheckout = ({ params: { userCartId, cartId }, body }) =>
  HttpService.postWithAuth(DEFAULT_CART_CHECKOUT({ userCartId, cartId }), body);

export const updateCartItem = ({ userCartId, cartId, cartItemId, data }) =>
  HttpService.postWithAuth(
    CART_ITEM_OPERATION_URL({ userCartId, cartId, cartItemId }),
    data
  );

export const deleteItemFromCart = ({ userCartId, cartItemId, cartId }) =>
  HttpService.deleteWithoutAuth(
    CART_ITEM_OPERATION_URL({ userCartId, cartId, cartItemId }),
    { cartItemId, cartId }
  );

export const getCartDetailById = async ({ cartId }) => {
  const { id: userCartId } = getAuth() || {};
  const {
    entity: cartDetails,
    status,
    message
  } = await getCartDetails({ userCartId, cartId });
  return { cartDetails, status, message };
};

const cartItemGroupTypeFilters = {
  CHECKOUT_READY: (items) =>
    items.filter(
      ({ status: cartItemStatus, product: { status: productStatus } }) =>
        productStatus === PRODUCT_STATUS.PUBLISHED &&
        cartItemStatus === CART_ITEM_STATUS.ACCEPTED
    ),
  REQUESTED: (items) =>
    items.filter(
      ({ status: cartItemStatus, product: { status: productStatus } }) =>
        productStatus === PRODUCT_STATUS.PUBLISHED &&
        [CART_ITEM_STATUS.REQUESTED].includes(cartItemStatus)
    ),
  REQUEST_PENDING: (items) =>
    items.filter(
      ({ status: cartItemStatus, product: { status: productStatus } }) =>
        productStatus === PRODUCT_STATUS.PUBLISHED &&
        [CART_ITEM_STATUS.REQUEST_PENDING].includes(cartItemStatus)
    ),
  UNAVAILABLE: (items) =>
    items.filter(
      ({ product: { status: productStatus } }) =>
        productStatus !== PRODUCT_STATUS.PUBLISHED
    )
};

export const filterItemsByCartItemGroupType = ({ type, items = [] }) =>
  cartItemGroupTypeFilters[type](items);

export const getPriceLabel = ({
  minPrice,
  productType,
  unitListedPrice,
  unitSellingPrice,
  quantity
}) => {
  const priceToFormat = unitListedPrice || unitSellingPrice;

  if (productType === PRODUCT_TYPE.QUOTE) {
    return unitListedPrice
      ? `AED ${parseFormatPriceValueFromAPI(priceToFormat * quantity)}`
      : `Price starting from AED ${parseFormatPriceValueFromAPI(minPrice)}`;
  }
  return `AED ${parseFormatPriceValueFromAPI(priceToFormat * quantity)}`;
};

export const transformCartItemsForQuote = ({ cartItems }) =>
  cartItems.map(
    ({
      cartItemMedia,
      derivedValues: { unitSellingPrice, unitListedPrice },
      id,
      product: { name: productName, productMedia, status: productStatus },
      productNotes,
      quantity,
      showProductMedia
    }) => ({
      ...itemMediaListMapper({
        additionalMediaList: cartItemMedia,
        product: {
          productMedia
        },
        showProductMedia,
        source: CART_ORDER_SOURCE.CART
      }),
      id,
      productSource: CART_ORDER_SOURCE.CART,
      productName,
      productNotes,
      productStatus,
      quantity,
      unitListedPrice,
      unitSellingPrice
    })
  );

export const transformDeliveryDetailForMobile = ({
  externalNotes,
  pickupDate,
  pickupTime = '',
  deliveryTime = '',
  deliveryDate
}) => ({
  externalNotes,
  pickupDate: pickupDate || '',
  deliveryDate: deliveryDate || '',
  pickupTime,
  deliveryTime
});

const sortEventDetailsForDisplay = (
  { userEventCartOrderDetailsBody, ...rest },
  isMobile
) => {
  const sortKey = isMobile ? 'sortOrderMobile' : 'sortOrderWeb';
  return {
    ...rest,
    userEventCartOrderDetailsBody: Object.values(
      userEventCartOrderDetailsBody
    ).sort((a, b) => a[sortKey] - b[sortKey])
  };
};

export const getUserEventDetails = ({ isMobile, readOnlyCartDetail }) => {
  const {
    deliveryDate,
    pickupDate,
    userEventSummary,
    userCart: { hostCreditEntity, mobile: eventContactNumber }
  } = readOnlyCartDetail;
  const { name: hostCreditEntityName = null } = hostCreditEntity || {};
  const [userEventDetails] = userEventSummary?.userEventDetails || [];

  const {
    checkoutEvent: {
      event: { name: checkoutEventName }
    },
    eventAddress,
    eventContactName,
    eventDate,
    eventStartTime,
    opsUser,
    trnNumber
  } = userEventDetails;

  return sortEventDetailsForDisplay(
    {
      userEventCartOrderDetailsBanner: {
        eventName: {
          value: checkoutEventName || 'NA',
          sortOrderMobile: null,
          sortOrderWeb: null
        },
        trnNumber: {
          value: trnNumber || '',
          sortOrderMobile: null,
          sortOrderWeb: null
        }
      },
      userEventCartOrderDetailsBody: {
        deliveryDate: {
          alt: 'delivery date',
          className: 'hidden md:flex',
          icon: 'delivery-truck.svg',
          sortOrderMobile: 1,
          sortOrderWeb: 4,
          value: isValidDate(deliveryDate)
            ? convertToShortMonthDateFormat(deliveryDate)
            : ''
        },
        eventAddress: {
          alt: 'event address',
          icon: 'location-icon-with-frame.svg',
          sortOrderMobile: 7,
          sortOrderWeb: 7,
          value: eventAddress
        },

        hostCreditEntityName: {
          alt: 'host credit entity',
          icon: 'host-credit-entity-brand-icon.svg',
          sortOrderMobile: 1,
          sortOrderWeb: 1,
          value: hostCreditEntityName
        },
        trnNumber: {
          alt: 'event contact name',
          icon: 'document-icon-red.svg',
          sortOrderMobile: 2,
          sortOrderWeb: 5,
          value: trnNumber
        },
        eventContactName: {
          alt: 'event contact name',
          icon: 'user-red-with-frame.svg',
          sortOrderMobile: 3,
          sortOrderWeb: 2,
          value: eventContactName
        },
        eventContactNumber: {
          alt: 'event contact number',
          icon: 'phone-brand-icon.svg',
          sortOrderMobile: 4,
          sortOrderWeb: 6,
          value: eventContactNumber
        },
        eventDate: {
          alt: 'event date',
          icon: 'calendar-red-with-frame.svg',
          sortOrderMobile: 5,
          sortOrderWeb: 3,
          value: convertToDateTimeString({
            date: eventDate,
            time: eventStartTime
          })
        },
        pickupDate: {
          alt: 'pickup date',
          className: 'hidden md:flex',
          icon: 'pickup-truck.svg',
          sortOrderMobile: 1,
          sortOrderWeb: 9,
          value: isValidDate(pickupDate)
            ? convertToShortMonthDateFormat(pickupDate)
            : ''
        },
        opsUser: {
          alt: 'planner name',
          className: 'md:hidden',
          icon: 'planner-icon-orange.svg',
          sortOrderMobile: 7,
          sortOrderWeb: 7,
          value: opsUser?.name || ''
        }
      }
    },
    isMobile
  );
};

export const termsAndConditions = [
  'Items are subject to availability upon confirmation.',
  `100% advance payment is mandatory to block the order.`,
  `All work permits, permissions and passes have to be arranged and
  provided by the client prior to the installation date to avoid any delay.`
];
